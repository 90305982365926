import './onboarding.css';
import React from "react";
import {Button, Checkbox, Col, Form, Input, InputNumber, Modal, Popover, Row} from "antd";
import {ArrowRightOutlined} from "@ant-design/icons";
import {AuthLogo} from "../../components/AuthLogo";

export function Onboarding() {
    const [step, setStep] = React.useState(1);
    const [subStep, setSubStep] = React.useState(1);
    const [bgImageClass, setBgImageClass] = React.useState('');
    const [restaurant, setRestaurant] = React.useState('');
    const [menuItem, setMenuItem] = React.useState(1);
    const [menuPrices, setMenuPrices] = React.useState({});
    const [targetMembers, setTargetMembers] = React.useState('');
    const [millenialsDone, setMillenialsDone] = React.useState(false);
    const [strategy, setStrategy] = React.useState('');
    const [minOrderValue, setMinOrderValue] = React.useState(0);
    const [domainName, setDomainName] = React.useState('');
    const [sqft, setSqft] = React.useState(0);
    const [employees, setEmployees] = React.useState(0);
    const [runningCost, setRunningCost] = React.useState(0);
    const baseRentRate = 2.67;
    const empHourlyRate = 10;
    const maxCombos = 2;
    const [totalRent, setTotalRent] = React.useState(0);
    const [showComboModal, setShowComboModal] = React.useState(false);
    const [comboValues, setComboValues] = React.useState({});
    const [comboModalCombo, setComboModalCombo] = React.useState(1);
    const [marketingType, setMarketingType] = React.useState('');
    const [developerLevel, setDeveloperLevel] = React.useState('');
    const [developerFee, setDeveloperFee] = React.useState(0);
    const [socialMedia, setSocialMedia] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [emotion, setEmotion] = React.useState('');
    const [campaignType, setCampaignType] = React.useState('');
    const [campaignMethod, setCampaignMethod] = React.useState('');
    const [keywords, setKeywords] = React.useState('');
    const [outreachEmail, setOutReachEmail] = React.useState('');

    const nextStepAuto = () => {
        if (step === 1)
            setStep(step + 1);
    }

    const nextStep = () => {
        if (step + 1 >= 5) {
            setBgImageClass('onboarding-bg2');
        }
        if (step + 1 >= 11) {
            setBgImageClass('onboarding-bg3');
        }
        if(step + 1 >= 18){
            setBgImageClass('onboarding-bg5');
        }
        if(step + 1 >= 23){
            setBgImageClass('onboarding-bg3');
        }
        setSubStep(1);
        setStep(step + 1);
    }

    const nextSubStep = () => {
        setSubStep(subStep + 1);
    }

    const prevStep = () => {
        setStep(step - 1);
    }

    const nextMenuItem = () => {
        setMenuItem(menuItem + 1)
    }

    const onFinishRestaurant = (values) => {
        setRestaurant(values.name);
        nextStep();
    }

    const onFinishOrderValue = (values) => {
        setMinOrderValue(values.min_value);
        nextSubStep();
    }

    const onFinishMenuItems = (values) => {
        setMenuPrices(values);
        nextStep();
    }

    const onFinishDomainName = (values) => {
        setDomainName(values.name);
        nextSubStep();
    }

    const setSq = (sq) => {
        setSqft(sq);
        setTotalRent(sq * baseRentRate);
        switch (sq) {
            case 1000:
                setEmployees(2);
                setRunningCost(100);
                break;
            case 2000:
                setEmployees(2);
                setRunningCost(200);
                break;
            case 3000:
                setEmployees(4);
                setRunningCost(300);
                break;
            case 5000:
                setEmployees(5);
                setRunningCost(400);
                break;
            default:
                setEmployees(0);
                setRunningCost(0);
        }
        nextStep();
    }

    const updateTargetMembers = (target) => {
        if (target === "millennials") {
            setBgImageClass('millennials-bg');
        }
        if (target === "gen-x") {
            nextStep();
        }
        setTargetMembers(target);
    }

    const updateStrategy = (s) => {
        nextSubStep();
        setStrategy(s);
    }

    const onFinishCombo = (values) => {
        setComboValues(values);
        setShowComboModal(false);
        setSubStep(subStep + 1);
    }

    const completeMillenials = () => {
        setSubStep(1);
        setMillenialsDone(true);
        setBgImageClass('onboarding-bg3');
    }

    const enableWebsite = () => {
        setSubStep(1);
        setBgImageClass('onboarding-bg4');
        setMarketingType("website");
    }

    const updateDeveloperLevel = (level) => {
        setDeveloperLevel(level);
        if (level === "fresher") {
            nextSubStep();
            setDeveloperFee(200);
        } else if (level === "intermediate") {
            setSubStep(1);
            setDeveloperFee(400);
            nextStep();
        } else if (level === "expert") {
            setDeveloperFee(1000);
            nextSubStep();
        }
    }

    const onFinishSocialMedia = (values) => {
        setSocialMedia(values.handles);
        nextSubStep();
    }

    const onFinishEmail = (values) => {
        nextSubStep();
        setEmail(values.email);
    }

    const updateEmotion = (e) => {
        setEmotion(e);
        nextStep();
    }

    const updateCampaignMethod = (method) => {
        setCampaignMethod(method);
        nextSubStep();
    }

    const onFinishKeywords = (values) => {
        setKeywords(values.keywords);
        nextSubStep();
    }

    const onFinishOutReachEmail = (values) => {
        setOutReachEmail(values.email);
        nextSubStep();
    }

    return (
        <div className={"onboarding-bg " + bgImageClass} onClick={nextStepAuto}>
            {step === 1 && <div className="onboarding-text gradient-border">
                Greetings, my name is Julian Kelly, and I am your virtual business consultant. I will guide you
                throughout this simulator and help you navigate through bottle necks.
            </div>}
            {step === 2 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    Are you ready to embark a business journey of your life?
                </div>
                <Row justify={"center"} align={"middle"} className="w-100 onboarding-actions">
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Let’s Go</Button>
                    </Col>
                </Row>
            </div>}
            {step === 3 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    And your gender?
                </div>
                <Row justify={"space-between"} align={"middle"} className="w-100 onboarding-actions">
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Male</Button>
                    </Col>
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Female</Button>
                    </Col>
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Not To
                            Disclose</Button>
                    </Col>
                </Row>
            </div>}
            {step === 4 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    Here is your welcome gift. We have opened your bank account, and credited $10,000 amount to initiate
                    your business. Spend wisely and make profits.
                </div>
                <div className="onboarding-actions">
                    <Row justify={"end"} align={"middle"} className="w-100">
                        <Col span={3}>
                            <Button onClick={nextStep} type="button" className="arrow-button gradient-border m-0"
                                    htmlType="button">
                                <ArrowRightOutlined/>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 5 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    That's all for now. Lets dive into your restaurant business
                </div>
                <div className="onboarding-actions">
                    <Row justify={"end"} align={"middle"} className="w-100">
                        <Col span={3}>
                            <Button onClick={nextStep} type="button" className="arrow-button gradient-border m-0"
                                    htmlType="button">
                                <ArrowRightOutlined/>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 6 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    First things first, we need to register your restaurant. Enter the name of your restaurant
                </div>
                <div className="onboarding-actions">
                    <Form layout="vertical"
                          requiredMark={false}
                          onFinish={onFinishRestaurant}
                          className="w-100"
                    >
                        <Row justify={"end"} align={"middle"} className="w-100">
                            <Col span={6}>
                                <Form.Item
                                    hasFeedback
                                    name="name"
                                    rules={[{required: true, message: 'Please enter restaurant name!'}]}
                                >
                                    <Input placeholder="Restaurant name" rootClassName="form-control"/>
                                </Form.Item>
                            </Col>
                            <Col span={6} offset={3}>
                                <Button type="submit" className="arrow-button gradient-border m-0" htmlType="submit">
                                    <ArrowRightOutlined/>
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>}
            {step === 7 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    Now for the sake of starting, we will open a sole proprietorship firm
                </div>
                <div className="onboarding-actions">
                    <Row justify={"end"} align={"middle"} className="w-100">
                        <Col span={3}>
                            <Button onClick={nextStep} type="button" className="arrow-button gradient-border m-0"
                                    htmlType="button">
                                <ArrowRightOutlined/>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 8 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    The licensing, registration and legal work of “{restaurant}” firm will cost $200
                </div>
                <div className="onboarding-actions">
                    <Row justify={"center"} align={"middle"} className="w-100">
                        <Col span={6}>
                            <Button onClick={nextStep} className="onboarding-button gradient-border">Pay $200</Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 9 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    Congratulations on completing the legal part. Now you are the official owner of “{restaurant}” firm.
                    Let us move to the next step of setting up our fast food restaurant.
                </div>
                <div className="onboarding-actions">
                    <Row justify={"end"} align={"middle"} className="w-100">
                        <Col span={3}>
                            <Button onClick={nextStep} type="button" className="arrow-button gradient-border m-0"
                                    htmlType="button">
                                <ArrowRightOutlined/>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 10 && <div className="w-100">
                <div>
                    <img src="/assets/images/person-idea.png" alt="idea" className="onboarding-image-left"/>
                </div>
                <div className="onboarding-text-dialogue">
                    <p>Keep in mind the 7ps of marketing, that is product, price, place, promotion, people, process, and
                        physical evidence.<br/><br/>Read More</p>
                </div>
                <div className="onboarding-text gradient-border">
                    For the grand opening of our “{restaurant}” restaurant, we need to design a marketing strategy and
                    allocate budget to it
                </div>
                <div className="onboarding-actions">
                    <Row justify={"end"} align={"middle"} className="w-100">
                        <Col span={3}>
                            <Button onClick={nextStep} type="button" className="arrow-button gradient-border m-0"
                                    htmlType="button">
                                <ArrowRightOutlined/>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 11 && <div className="w-100">
                <div>
                    <img src="/assets/images/person-thinking.png" alt="idea" className="onboarding-image-left"/>
                </div>
                <div className="onboarding-text-dialogue">
                    <p>Just note tha high sqft will result in higher rental, staff and operational cost. But will also
                        drive more sales!<br/><br/>Choose Wisely</p>
                </div>
                <div className="onboarding-text gradient-border">
                    Choose how many sqft area you want to rent for your restaurant? The current rental is $2.67 Per
                    Sqft. per month
                </div>
                <div className="onboarding-actions">
                    <Row justify={"space-around"} align={"middle"} className="w-100 onboarding-actions">
                        <Col span={6}>
                            <Button onClick={() => setSq(1000)} className="onboarding-button gradient-border">1,000
                                Sqft</Button>
                            <Button onClick={() => setSq(2000)} className="onboarding-button gradient-border mt-20">2,000
                                Sqft</Button>
                        </Col>
                        <Col span={6}>
                            <Button onClick={() => setSq(3000)} className="onboarding-button gradient-border">3,000
                                Sqft</Button>
                            <Button onClick={() => setSq(5000)} className="onboarding-button gradient-border mt-20">5,000
                                Sqft</Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 12 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    The total payment for {sqft}sqft restaurant for a month will be ${sqft * baseRentRate}. This amount will be deducted on the 1st of every month from your bak account.
                </div>
                <div className="onboarding-actions">
                    <Row justify={"space-around"} align={"middle"} className="w-100 onboarding-actions">
                        <Col span={6}>
                            <Button onClick={nextStep} className="onboarding-button gradient-border">Make Payment of
                                ${totalRent}</Button>
                        </Col>
                        <Col span={6}>
                            <Button onClick={prevStep} className="onboarding-button gradient-border">Rethink about
                                decision!</Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 13 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    We will need at least {employees} employees to start with. The average wage here is
                    ${empHourlyRate} per hour
                </div>
                <div className="onboarding-actions">
                    <Row justify={"space-around"} align={"middle"} className="w-100 onboarding-actions">
                        <Col span={6}>
                            <Button onClick={nextStep} className="onboarding-button gradient-border">Make Payment of
                                ${empHourlyRate * employees * 30 * 8} for month</Button>
                        </Col>
                        <Col span={6}>
                            <Button onClick={() => setStep(11)} className="onboarding-button gradient-border">Rethink
                                about
                                decision!</Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 14 && <div className="w-100">
                <div className="onboarding-text-dialogue dialogue-right">
                    <p>The running cost include utilities, restocking, gas, maintenance, electricity, food and logistics
                        among other monthly expenses. </p>
                </div>
                <div className="onboarding-text gradient-border">
                    Depending upon the choices made, the running cost of restaurant per month will be ${runningCost}.
                    The payment needs to be done at 1st of every month.
                </div>
                <div className="onboarding-actions">
                    <Row justify={"space-around"} align={"middle"} className="w-100 onboarding-actions">
                        <Col span={6}>
                            <Button onClick={nextStep} className="onboarding-button gradient-border">
                                Make Payment of ${runningCost}</Button>
                        </Col>
                        <Col span={6}>
                            <Button onClick={() => setStep(11)} className="onboarding-button gradient-border">Rethink
                                about
                                decision!</Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 15 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    For starter, we will only keep burger, fires, soda, and milkshake. Please enter the pricing you want
                    for each of them
                </div>
                <div className="onboarding-actions">
                    <Form layout="vertical"
                          requiredMark={false}
                          onFinish={onFinishMenuItems}
                          className="w-100"
                    >
                        {menuItem === 1 &&
                            <Row justify={"space-between"} align={"middle"} className="w-100">
                                <Col span={6} offset={3}>
                                    <Button htmlType={"button"} className="onboarding-button gradient-border">
                                        Burger</Button>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        hasFeedback
                                        name="burger_price"
                                        className={"m-0"}
                                        rules={[{required: true, message: 'Please enter burger price'}]}
                                    >
                                        <InputNumber placeholder="Burger price" min={0.1} step={"any"}
                                                     rootClassName="form-control"/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Button onClick={nextMenuItem} type="submit"
                                            className="arrow-button gradient-border m-0" htmlType="button">
                                        <ArrowRightOutlined/>
                                    </Button>
                                </Col>
                            </Row>
                        }
                        {menuItem === 2 &&
                            <Row justify={"space-between"} align={"middle"} className="w-100">
                                <Col span={6} offset={3}>
                                    <Button htmlType={"button"} className="onboarding-button gradient-border">
                                        Fries</Button>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        hasFeedback
                                        name="fries_price"
                                        className={"m-0"}
                                        rules={[{required: true, message: 'Please enter fries price'}]}
                                    >
                                        <InputNumber placeholder="Fries price" min={0.1} step={"any"}
                                                     rootClassName="form-control"/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Button type="submit" onClick={nextMenuItem}
                                            className="arrow-button gradient-border m-0" htmlType="button">
                                        <ArrowRightOutlined/>
                                    </Button>
                                </Col>
                            </Row>
                        }
                        {menuItem === 3 &&
                            <Row justify={"space-between"} align={"middle"} className="w-100">
                                <Col span={6} offset={3}>
                                    <Button htmlType={"button"} className="onboarding-button gradient-border">
                                        Soda</Button>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        hasFeedback
                                        name="soda_price"
                                        className={"m-0"}
                                        rules={[{required: true, message: 'Please enter soda price'}]}
                                    >
                                        <InputNumber placeholder="Fries price" min={0.1} step={"any"}
                                                     rootClassName="form-control"/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Button type="submit" onClick={nextMenuItem}
                                            className="arrow-button gradient-border m-0" htmlType="button">
                                        <ArrowRightOutlined/>
                                    </Button>
                                </Col>
                            </Row>
                        }
                        {menuItem === 4 &&
                            <Row justify={"space-between"} align={"middle"} className="w-100">
                                <Col span={6} offset={3}>
                                    <Button htmlType={"button"} className="onboarding-button gradient-border">
                                        Milkshake</Button>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        hasFeedback
                                        name="milkshake_price"
                                        className={"m-0"}
                                        rules={[{required: true, message: 'Please enter milkshake price'}]}
                                    >
                                        <InputNumber placeholder="Milkshake price" min={0.1} step={"any"}
                                                     rootClassName="form-control"/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Button type="submit" onClick={onFinishMenuItems}
                                            className="arrow-button gradient-border m-0" htmlType="submit">
                                        <ArrowRightOutlined/>
                                    </Button>
                                </Col>
                            </Row>
                        }
                    </Form>
                </div>
            </div>}
            {step === 16 && <div className="w-100">
                {targetMembers === "" &&
                    <div>
                        <div className="onboarding-text gradient-border">
                            Now we need to design a promotion strategy. For effective results, we need to identify
                            different audience and target each of them. Select the audience you want to target first
                        </div>
                        <Row justify={"space-between"} align={"middle"} className="w-100 onboarding-actions">
                            <Col span={6}>
                                <Button onClick={() => updateTargetMembers('gen-z')}
                                        className="onboarding-button gradient-border">Gen Z & Early Adopters</Button>
                            </Col>
                            <Col span={6}>
                                <Button onClick={() => updateTargetMembers('millennials')}
                                        className="onboarding-button gradient-border">Millennials & working
                                    class </Button>
                            </Col>
                            <Col span={6}>
                                <Button onClick={() => updateTargetMembers('gen-x')}
                                        className="onboarding-button gradient-border">Gen X & Boomers</Button>
                            </Col>
                        </Row>
                    </div>
                }
                {(targetMembers === "gen-z" || millenialsDone) &&
                    <div className={"w-100"}>
                        <div>
                            <img src="/assets/images/person-thumbs-up.png" alt="idea"
                                 className="onboarding-image-left"/>
                        </div>
                        <div className="onboarding-text-dialogue">
                            <p>Remember this target audience is highly active on social media and get greatly influenced
                                by
                                those campaigns<br/><br/>Choose Wisely</p>
                        </div>
                        <div className="onboarding-text gradient-border">
                            Great choice, now let us design a campaign revolving around Gen Z. Select the strategy that
                            you want
                            to adopt first
                        </div>
                        <Row justify={"space-around"} align={"middle"} className="w-100 onboarding-actions">
                            <Col span={6}>
                                <Button onClick={nextStep} className="onboarding-button gradient-border">Online
                                    Marketing</Button>
                            </Col>
                            <Col span={6}>
                                <Button onClick={nextStep} className="onboarding-button gradient-border">Offline
                                    Marketing</Button>
                            </Col>
                            {/*<Col span={6}>*/}
                            {/*    <Button onClick={nextStep} className="onboarding-button gradient-border">Hybrid*/}
                            {/*        Campaign</Button>*/}
                            {/*</Col>*/}
                        </Row>
                    </div>
                }
                {targetMembers === "millennials" && !millenialsDone &&
                    <div>
                        {subStep === 1 &&
                            <>
                                <div className="onboarding-text gradient-border">
                                    Great choice selecting millennials and working class people
                                </div>
                                <Row justify={"end"} align={"middle"} className="w-100 onboarding-actions">
                                    <Col span={3}>
                                        <Button onClick={nextSubStep} type="button"
                                                className="arrow-button gradient-border m-0" htmlType="button">
                                            <ArrowRightOutlined/>
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                        {subStep === 2 &&
                            <>
                                <div className="onboarding-text gradient-border">
                                    While these age group is quite influenced by social media, it is important to tap on
                                    their requirements as well. Select which strategy you want to opt first
                                </div>
                                <Row justify={"space-between"} align={"middle"} className="w-100 onboarding-actions">
                                    <Col span={6}>
                                        <Button onClick={() => updateStrategy('special-combo')}
                                                className="onboarding-button gradient-border">Special Combo in Office
                                            Hours</Button>
                                    </Col>
                                    <Col span={6}>
                                        <Button onClick={() => updateStrategy('drive-through')}
                                                className="onboarding-button gradient-border">Drive Through</Button>
                                    </Col>
                                    <Col span={6}>
                                        <Button onClick={() => updateStrategy('office-deliveries')}
                                                className="onboarding-button gradient-border">Office Deliveries</Button>
                                    </Col>
                                </Row>
                            </>
                        }
                        {strategy === "drive-through" &&
                            <>
                                {subStep === 3 &&
                                    <div>
                                        <div className="onboarding-text gradient-border">
                                            We can keep a comprehensive menu for drive through where customers can
                                            select from the menu as well as from the combo.
                                        </div>
                                        <Row justify={"end"} align={"middle"} className="w-100 onboarding-actions">
                                            <Col span={3}>
                                                <Button onClick={nextSubStep} type="button"
                                                        className="arrow-button gradient-border m-0" htmlType="button">
                                                    <ArrowRightOutlined/>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {subStep === 4 &&
                                    <div>
                                        <div className="onboarding-text gradient-border">
                                            We need to design a minimum of two combo that can be offered in drive
                                            through as of now. Let design it.
                                        </div>
                                        <Row justify={"end"} align={"middle"} className="w-100 onboarding-actions">
                                            <Col span={3}>
                                                <Button onClick={() => setShowComboModal(true)} type="button"
                                                        className="arrow-button gradient-border m-0" htmlType="button">
                                                    <ArrowRightOutlined/>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {subStep === 5 &&
                                    <div>
                                        <div className="onboarding-text gradient-border">
                                            Great, your combo are created. Do not worry, you can change that later from
                                            the dashboard. Now it is time to promote it
                                        </div>
                                        <Row justify={"end"} align={"middle"} className="w-100 onboarding-actions">
                                            <Col span={3}>
                                                <Button onClick={completeMillenials} type="button"
                                                        className="arrow-button gradient-border m-0" htmlType="button">
                                                    <ArrowRightOutlined/>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            </>
                        }
                        {strategy === "drive-through" &&
                            <>
                                {subStep === 3 &&
                                    <div>
                                        <div className="onboarding-text gradient-border">
                                            We need to design a minimum of two combo that office workers can select
                                            from. The combo will be available between 12:00pm to 3:00pm only
                                        </div>
                                        <Row justify={"end"} align={"middle"} className="w-100 onboarding-actions">
                                            <Col span={3}>
                                                <Button onClick={() => setShowComboModal(true)} type="button"
                                                        className="arrow-button gradient-border m-0" htmlType="button">
                                                    <ArrowRightOutlined/>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {subStep === 4 &&
                                    <div>
                                        <div className="onboarding-text gradient-border">
                                            Great, your combo are created. Do not worry, you can change that later from
                                            the dashboard. Now it is time to promote it
                                        </div>
                                        <Row justify={"end"} align={"middle"} className="w-100 onboarding-actions">
                                            <Col span={3}>
                                                <Button onClick={completeMillenials} type="button"
                                                        className="arrow-button gradient-border m-0" htmlType="button">
                                                    <ArrowRightOutlined/>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            </>
                        }
                        {strategy === "special-combo" &&
                            <>
                                {subStep === 3 &&
                                    <div>
                                        <div className="onboarding-text gradient-border">
                                            The office deliveries will only be possible if there will be a certain
                                            Minimum Order Quantity (MoQ)
                                        </div>
                                        <Row justify={"end"} align={"middle"} className="w-100 onboarding-actions">
                                            <Col span={3}>
                                                <Button onClick={nextSubStep} type="button"
                                                        className="arrow-button gradient-border m-0" htmlType="button">
                                                    <ArrowRightOutlined/>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {subStep === 4 &&
                                    <div>
                                        <div className="onboarding-text gradient-border">
                                            Including combos in office deliveries is a great way to ensure MoQ. Let us
                                            create at least 2 combo
                                        </div>
                                        <Row justify={"end"} align={"middle"} className="w-100 onboarding-actions">
                                            <Col span={3}>
                                                <Button onClick={() => setShowComboModal(true)} type="button"
                                                        className="arrow-button gradient-border m-0" htmlType="button">
                                                    <ArrowRightOutlined/>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {subStep === 5 &&
                                    <div>
                                        <div className="onboarding-text gradient-border">
                                            Great, your combo are created. Do not worry, you can change that later from
                                            the dashboard. Now it is time to promote it
                                        </div>
                                        <Row justify={"end"} align={"middle"} className="w-100 onboarding-actions">
                                            <Col span={3}>
                                                <Button onClick={nextSubStep} type="button"
                                                        className="arrow-button gradient-border m-0" htmlType="button">
                                                    <ArrowRightOutlined/>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {subStep === 6 &&
                                    <div>
                                        <div className="onboarding-text gradient-border">
                                            Now that we are done with making combo, let us put a MoQ in terms of USD.
                                            The staff will not provide deliveries if the client orders less than the MoQ
                                            order
                                        </div>
                                        <div className={"onboarding-actions"}>
                                            <Form layout="vertical"
                                                  requiredMark={false}
                                                  onFinish={onFinishOrderValue}
                                                  className="w-100"
                                            >
                                                <Row justify={"end"} align={"middle"} className="w-100">
                                                    <Col span={6}>
                                                        <Form.Item
                                                            hasFeedback
                                                            name="min_value"
                                                            rules={[{
                                                                required: true,
                                                                message: 'Please enter minimum order value!'
                                                            }]}
                                                        >
                                                            <InputNumber placeholder="Minimum Order Value (in $$)"
                                                                         rootClassName="form-control"/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6} offset={3}>
                                                        <Button type="submit"
                                                                className="arrow-button gradient-border m-0"
                                                                htmlType="submit">
                                                            <ArrowRightOutlined/>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </div>
                                }
                                {subStep === 7 &&
                                    <div>
                                        <div className="onboarding-text gradient-border">
                                            Things are going good. Our combos and MoQ’s are done. Now it is time to jump
                                            to the marketing part.
                                        </div>
                                        <Row justify={"end"} align={"middle"} className="w-100 onboarding-actions">
                                            <Col span={3}>
                                                <Button onClick={completeMillenials} type="button"
                                                        className="arrow-button gradient-border m-0" htmlType="button">
                                                    <ArrowRightOutlined/>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            </>
                        }
                    </div>
                }
            </div>}
            {step === 17 && <div className="w-100">
                {marketingType === "" && <>
                    <div>
                        <img src="/assets/images/person-thumbs-up.png" alt="idea" className="onboarding-image-left"/>
                    </div>
                    <div className="onboarding-text-dialogue">
                        <p>Remember this target audience is highly active on social media and get greatly influenced by
                            those campaigns<br/><br/>Choose Wisely</p>
                    </div>
                    <div className="onboarding-text gradient-border">
                        We need a strong digital presence for effective online marketing campaign. Choose and option that we want to establish first
                    </div>
                    <Row justify={"space-between"} align={"middle"} className="w-100 onboarding-actions">
                        <Col span={6}>
                            <Button onClick={enableWebsite} className="onboarding-button gradient-border">Make website & Business Profile</Button>
                        </Col>
                        <Col span={6}>
                            <Button onClick={nextStep} className="onboarding-button gradient-border">Create social media handles</Button>
                        </Col>
                        <Col span={6}>
                            <Button onClick={nextStep} className="onboarding-button gradient-border">Email Marketing Campaign</Button>
                        </Col>
                    </Row>
                </>
                }
                {marketingType === "website" && <>
                    {subStep === 1 && <>
                        <div className="onboarding-text gradient-border">
                            Ok, for developing a website, we will need a domain name and a hosting space!
                        </div>
                        <div className="onboarding-actions">
                            <Form layout="vertical"
                                  requiredMark={false}
                                  onFinish={onFinishDomainName}
                                  className="w-100"
                            >
                                <Row justify={"center"} align={"middle"} className="w-100">
                                    <Col span={6}>
                                        <Button htmlType={"button"} className="onboarding-button gradient-border">
                                            Domain name</Button>
                                    </Col>
                                    <Col span={6} offset={1}>
                                        <Form.Item
                                            hasFeedback
                                            name="name"
                                            className={"m-0"}
                                            rules={[{required: true, message: 'Please enter domain name'}]}
                                        >
                                            <Input placeholder="Enter Domain name" rootClassName="form-control"/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row justify={"center"} align={"middle"} className="w-100 mt-20">
                                    <Col span={6}>
                                        <Button htmlType={"submit"} className="onboarding-button gradient-border">
                                            Pay $20 for a year!</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </>}
                    {subStep === 2 && <>
                        <div>
                            <img src="/assets/images/person-thinking.png" alt="idea" className="onboarding-image-left"/>
                        </div>
                        <div className="onboarding-text-dialogue">
                            <p>Choosing a resource is an important aspect of business. The expert will be costlier, but
                                will guarantee work, while fresher might do some goof ups, but are really
                                economical.</p>
                        </div>
                        <div className="onboarding-text gradient-border">
                            Now we need to hire an independent contractor to develop our website
                        </div>
                        <Row justify={"space-between"} align={"middle"} className="w-100 onboarding-actions">
                            <Col span={6}>
                                <Button onClick={() => updateDeveloperLevel('fresher')}
                                        className="onboarding-button gradient-border">Hire a
                                    Fresher ($200, 2 Months to Deliver)</Button>
                            </Col>
                            <Col span={6}>
                                <Button onClick={() => updateDeveloperLevel('intermediate')}
                                        className="onboarding-button gradient-border">
                                    Intermediate Resource ($400, 1.5 Months To Deliver)</Button>
                            </Col>
                            <Col span={6}>
                                <Button onClick={() => updateDeveloperLevel('expert')}
                                        className="onboarding-button gradient-border">
                                    Expert Resource ($1,000, 20 Days To Deliver)</Button>
                            </Col>
                        </Row>
                    </>}
                    {subStep === 3 && <>
                        <div className="onboarding-text gradient-border">
                            Nice choice, lets pay the ${developerLevel} resource
                        </div>
                        <Row justify={"center"} align={"middle"} className="w-100 onboarding-actions">
                            <Col span={6}>
                                <Button onClick={nextSubStep} className="onboarding-button gradient-border">Pay
                                    ${developerFee}</Button>
                            </Col>
                        </Row>
                    </>}
                    {subStep >= 4 && developerLevel === "fresher" && <>
                        {subStep === 4 && <>
                            <div className="onboarding-text gradient-border">
                                ok, now that our website is Work In Progress (W.I.P), lets focus on creating a google
                                profile
                            </div>
                            <Row justify={"center"} align={"middle"} className="w-100 onboarding-actions">
                                <Col span={6}>
                                    <Button onClick={nextSubStep} className="onboarding-button gradient-border">Make a
                                        Google Profile</Button>
                                </Col>
                            </Row>
                        </>}
                        {subStep === 5 && <>
                            <div className="onboarding-text gradient-border">
                                Now it is time to make our social media handles..
                            </div>
                            <div className={"onboarding-actions"}>
                                <Form layout="vertical"
                                      requiredMark={false}
                                      onFinish={onFinishSocialMedia}
                                      className="w-100">
                                    <Row justify={"center"} align={"middle"} className="w-100">
                                        <Col span={6} offset={9}>
                                            <Button htmlType={"button"} className="onboarding-button gradient-border">
                                                Input Social Media Handles</Button>
                                            <Form.Item
                                                hasFeedback
                                                name="handles"
                                                className={"m-0"}
                                                rules={[{required: true, message: 'Please enter social media handles'}]}
                                            >
                                                <Input placeholder="Social Media Handles" rootClassName="form-control"/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} offset={3} className={"text-end"}>
                                            <Button type="submit" className="arrow-button gradient-border m-0"
                                                    htmlType="submit">
                                                <ArrowRightOutlined/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </>}
                        {subStep === 6 && <>
                            <div className="onboarding-text gradient-border">
                                Now we need to create a professional Email Id for official communication. The email will
                                cost you $5 per email per month
                            </div>
                            <div className="onboarding-actions">
                                <Form layout="vertical"
                                      requiredMark={false}
                                      onFinish={onFinishEmail}
                                      className="w-100"
                                >
                                    <Row justify={"center"} align={"middle"} className="w-100">
                                        <Col span={6}>
                                            <Button htmlType={"button"} className="onboarding-button gradient-border">
                                                Domain name</Button>
                                        </Col>
                                        <Col span={6} offset={1}>
                                            <Form.Item
                                                hasFeedback
                                                name="email"
                                                className={"m-0"}
                                                rules={[{required: true, message: 'Please enter email', type: "email"}]}
                                            >
                                                <Input placeholder="Youremail@businessname.com" rootClassName="form-control"/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify={"center"} align={"middle"} className="w-100 mt-20">
                                        <Col span={6}>
                                            <Button htmlType={"submit"} className="onboarding-button gradient-border">
                                                Pay $5</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </>}
                        {subStep === 7 && <>
                            <div className="onboarding-text gradient-border">
                                Great news, your social media handles and email id has been set up. You can
                                check/update/change their performance in dashboard
                            </div>
                            <div className="onboarding-actions">
                                <Row justify={"end"} align={"middle"} className="w-100">
                                    <Col span={3}>
                                        <Button onClick={nextStep} type="button"
                                                className="arrow-button gradient-border m-0"
                                                htmlType="button">
                                            <ArrowRightOutlined/>
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </>}
                    </>}
                    {subStep >= 4 && developerLevel === "expert" && <>
                        {subStep === 4 && <>
                            <div className="onboarding-text gradient-border">
                                Along with your website, the expert has also setup your google business profiles, social media handles and a professional email id. The mail will be charger $20 per month.
                            </div>
                            <div className="onboarding-actions">
                                <Row justify={"end"} align={"middle"} className="w-100">
                                    <Col span={3}>
                                        <Button onClick={nextSubStep} type="button"
                                                className="arrow-button gradient-border m-0"
                                                htmlType="button">
                                            <ArrowRightOutlined/>
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </>}
                        {subStep === 5 && <>
                            <div className="onboarding-text gradient-border">
                                You can check, update, manage your profiles and email in dashboard
                            </div>
                            <div className="onboarding-actions">
                                <Row justify={"end"} align={"middle"} className="w-100">
                                    <Col span={3}>
                                        <Button onClick={nextStep} type="button"
                                                className="arrow-button gradient-border m-0"
                                                htmlType="button">
                                            <ArrowRightOutlined/>
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </>}
                    </>}
                </>}
            </div>}
            {step === 18 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    Now that our digital presence is being established, let us think about business strategies
                </div>
                <div className="onboarding-actions">
                    <Row justify={"end"} align={"middle"} className="w-100">
                        <Col span={3}>
                            <Button onClick={nextStep} type="button"
                                    className="arrow-button gradient-border m-0"
                                    htmlType="button">
                                <ArrowRightOutlined/>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 19 && <div className="w-100">
                <div>
                    <img src="/assets/images/person-thinking.png" alt="idea" className="onboarding-image-left"/>
                </div>
                <div className="onboarding-text-dialogue">
                    <p>Understanding business strategies of other companies are really important to curate our own. You should consider going through at least 2-3 strategies.</p>
                </div>
                <div className="onboarding-text gradient-border">
                    Do you want to refer strategies practiced by some major businesses
                </div>
                <Row justify={"center"} align={"middle"} className="w-100 onboarding-actions">
                    <Col span={7}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Coco-Cola</Button>
                    </Col>
                    <Col span={7} offset={1}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Starbucks</Button>
                    </Col>
                    <Col span={7} offset={1}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Nike</Button>
                    </Col>
                    <Col span={7} className={"mt-20"}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Dove</Button>
                    </Col>
                    <Col span={7} offset={1} className={"mt-20"}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Skip this step</Button>
                    </Col>
                </Row>
            </div>}
            {step === 20 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    As we understood, all big companies trigger emotions to scale their brand perception and drive business. We should do the same. Which emotion you want to trigger?
                </div>
                <Row justify={"space-around"} align={"middle"} className="w-100 onboarding-actions">
                    <Col span={24} lg={7}>
                        <Button onClick={() => updateEmotion('Healthy Eating')} className="onboarding-button gradient-border info-button">Healthy Eating
                            <Popover content={<img src="/assets/images/healthy-eating.png" width="300" />}>
                                <img src="/assets/images/info-icon.png"/>
                            </Popover>
                        </Button>
                    </Col>
                    <Col span={24} lg={7}>
                        <Button onClick={() => updateEmotion('Fear of Missing Out')} className="onboarding-button gradient-border info-button">Fear of Missing Out
                            <Popover content={<img src="/assets/images/fomo.png" width="300" />}>
                                <img src="/assets/images/info-icon.png"/>
                            </Popover>
                        </Button>
                    </Col>
                </Row>
                <Row justify={"space-around"} align={"middle"} className="w-100 mt-20">
                    <Col span={24} lg={7}>
                        <Button onClick={() => updateEmotion('Heavy Discounts')} className="onboarding-button gradient-border info-button">Heavy Discounts
                            <Popover content={<img src="/assets/images/heavy-discounts.png" width="300" />}>
                                <img src="/assets/images/info-icon.png"/>
                            </Popover>
                        </Button>
                    </Col>
                    <Col span={24} lg={7}>
                        <Button onClick={() => updateEmotion('Quick Bite Quick Dollar')} className="onboarding-button gradient-border info-button">Quick Bite Quick Dollar
                            <Popover content={<img src="/assets/images/quick-bite-quick-dollar.png" width="300" />}>
                                <img src="/assets/images/info-icon.png"/>
                            </Popover>
                        </Button>
                    </Col>
                </Row>
            </div>}
            {step === 21 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    Nice choice selecting {emotion} strategy. Now we need to make changes in our menu and pricing as per the strategy selected
                </div>
                <div className="onboarding-actions">
                    <Row justify={"center"} align={"middle"} className="w-100">
                        <Col span={6}>
                            <button onClick={nextStep} className="onboarding-button gradient-border">Go To Dashboard</button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 22 && <div className="w-100">
                { campaignType === "" && <>
                    <div className="onboarding-text gradient-border">
                        Great Job, our strategies are noted down, our infrastructure is ready, our website and social
                        handles are ready. It is time for execution.
                    </div>
                    <div className="onboarding-actions">
                        <Row justify={"space-around"} align={"middle"} className="w-100">
                            <Col span={6}>
                                <button onClick={() => {
                                    setCampaignType('online');
                                }} className="onboarding-button gradient-border">Execute Online
                                    Campaign
                                </button>
                            </Col>
                            <Col span={6}>
                                <button onClick={() => {
                                    setCampaignType('offline');
                                    nextStep();
                                }} className="onboarding-button gradient-border">Execute Offline
                                    Campaign
                                </button>
                            </Col>
                        </Row>
                    </div>
                </>}
                { campaignType === "online" && <>
                    { subStep === 1 && campaignMethod === "" && <>
                        <div className="onboarding-text gradient-border">
                            let us start executing campaigns one by one, which campaign you want to execute first
                        </div>
                        <div className="onboarding-actions">
                            <Row justify={"space-around"} align={"middle"} className="w-100">
                                <Col span={6}>
                                    <button onClick={() => {
                                        setCampaignMethod('social-media');
                                    }} className="onboarding-button gradient-border">Social Media
                                    </button>
                                </Col>
                                <Col span={6}>
                                    <button onClick={() => {
                                        setCampaignMethod('google-ads');
                                        nextSubStep();
                                    }} className="onboarding-button gradient-border">Google Ads
                                        Campaign
                                    </button>
                                </Col>
                                <Col span={6}>
                                    <button onClick={() => {
                                        setCampaignMethod('email-outreach');
                                        nextSubStep();
                                    }} className="onboarding-button gradient-border">Email Outreach
                                    </button>
                                </Col>
                            </Row>
                        </div>
                    </>}
                    {campaignMethod === "social-media" && <>
                        <div className="onboarding-text gradient-border">
                            Run social media campaign. These campaigns will run for a month and provide a reach accordingly.
                        </div>
                        <div className="onboarding-actions">
                            <Row justify={"space-around"} align={"middle"} className="w-100">
                                <Col span={6}>
                                    <button onClick={nextStep} className="onboarding-button gradient-border">$100 for 3K
                                        reach
                                    </button>
                                </Col>
                                <Col span={6}>
                                    <button onClick={nextStep} className="onboarding-button gradient-border">$300 for 12K reach
                                    </button>
                                </Col>
                                <Col span={6}>
                                    <button onClick={nextStep} className="onboarding-button gradient-border">$500 for 30K reach
                                    </button>
                                </Col>
                            </Row>
                        </div>
                    </>}
                    {campaignMethod === "google-ads" && <>
                        {subStep === 2 && <>
                            <div>
                                <img src="/assets/images/person-idea.png" alt="idea"
                                     className="onboarding-image-left"/>
                            </div>
                            <div className="onboarding-text-dialogue">
                                <p>The better the keyword, the better the targeting and higher rate of conversion<br/><br/>Read case studies about how real companies are using keyword targeting!</p>
                            </div>
                            <div className="onboarding-text gradient-border">
                                For Google Ads, you need to input keywords that you need to target
                            </div>
                            <div className="onboarding-actions">
                                <Form layout="vertical"
                                      requiredMark={false}
                                      onFinish={onFinishKeywords}>
                                    <Row justify={"space-around"} align={"middle"} className="w-100">
                                        <Col span={24} lg={18}>
                                            <Row justify={"space-around"} align={"middle"} className="w-100">
                                                <Col span={6}>
                                                    <Form.Item
                                                        hasFeedback
                                                        name="keywords[0]"
                                                        className={"m-0"}
                                                        rules={[{required: true, message: 'Please enter keyword'}]}
                                                    >
                                                        <Input placeholder="Keyword 1" rootClassName="form-control"/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item
                                                        hasFeedback
                                                        name="keywords[1]"
                                                        className={"m-0"}
                                                    >
                                                        <Input placeholder="Keyword 2" rootClassName="form-control"/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row justify={"space-around"} align={"middle"} className="w-100 mt-20">
                                                <Col span={6}>
                                                    <Form.Item
                                                        hasFeedback
                                                        name="keywords[2]"
                                                        className={"m-0"}
                                                    >
                                                        <Input placeholder="Keyword 3" rootClassName="form-control"/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item
                                                        hasFeedback
                                                        name="keywords[3]"
                                                        className={"m-0"}
                                                    >
                                                        <Input placeholder="Keyword 4" rootClassName="form-control"/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={24} lg={6}>
                                            <Button type="submit"
                                                    className="arrow-button gradient-border m-0"
                                                    htmlType="submit">
                                                <ArrowRightOutlined/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </>}
                        {subStep === 3 && <>
                            <div className="onboarding-text gradient-border">
                                Based on your selected keyword, here is your costing for google Ads
                            </div>
                            <Row justify={"space-around"} align={"middle"} className="w-100 onboarding-actions">
                                <Col span={24} lg={7}>
                                    <Button onClick={nextStep}
                                            className="onboarding-button gradient-border">$100 for 1K reach
                                    </Button>
                                </Col>
                                <Col span={24} lg={7}>
                                    <Button onClick={nextStep}
                                            className="onboarding-button gradient-border">$200 for 3K reach
                                    </Button>
                                </Col>
                            </Row>
                            <Row justify={"space-around"} align={"middle"} className="w-100 mt-20">
                                <Col span={24} lg={7}>
                                    <Button onClick={nextStep}
                                            className="onboarding-button gradient-border">$300 for 4K reach
                                    </Button>
                                </Col>
                                <Col span={24} lg={7}>
                                    <Button onClick={nextStep}
                                            className="onboarding-button gradient-border">$500 for 8K reach
                                    </Button>
                                </Col>
                            </Row>
                        </>}
                    </>}
                    {campaignMethod === "email-outreach" && <>
                        {subStep === 2 && <>
                            <div className="onboarding-text gradient-border">
                                For Email marketing campaign, we will need a professional email for outreach, and a subscription service. Note that you should not use your primary email for outreach campaigns.
                            </div>
                            <div className="onboarding-actions">
                                <Form layout="vertical"
                                      requiredMark={false}
                                      onFinish={onFinishOutReachEmail}
                                      className="w-100"
                                >
                                    <Row justify={"space-between"} align={"middle"} className="w-100">
                                        <Col span={6} offset={3}>
                                            <Button htmlType={"button"} className="onboarding-button gradient-border">
                                                Select Email For Outreach</Button>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                hasFeedback
                                                name="email"
                                                className={"m-0"}
                                                rules={[{required: true, message: 'Please enter email', type: "email"}]}
                                            >
                                                <Input placeholder="Email" rootClassName="form-control"/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Button onClick={nextMenuItem} type="submit"
                                                    className="arrow-button gradient-border m-0" htmlType="submit">
                                                <ArrowRightOutlined/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </>}
                        { subStep === 3 && <>
                            <div>
                                <img src="/assets/images/person-hand.png" alt="idea" className="onboarding-image-left"/>
                            </div>
                            <div className="onboarding-text-dialogue">
                                <p>Hey..its just not about the selecting highest number of emails, you also need to manage the number of subscriptions....</p>
                            </div>
                            <div className="onboarding-text-dialogue dialogue-right">
                                <p>If you have higher subscribers, but limited sending capability, or you have higher
                                    sending capability and lower subscriber, it will be loss in either case.</p>
                            </div>
                            <div className="onboarding-text gradient-border">
                                Now that we have selected and email, we need to select and outreach service provider.
                                Note that this will be a monthly subscription. You will be able to change it later in
                                dashboard
                            </div>
                            <Row justify={"space-around"} align={"middle"} className="w-100 onboarding-actions">
                                <Col span={24} lg={7}>
                                    <Button onClick={nextSubStep}
                                            className="onboarding-button gradient-border">$20 for 100 Emails
                                    </Button>
                                </Col>
                                <Col span={24} lg={7}>
                                    <Button onClick={nextSubStep}
                                            className="onboarding-button gradient-border">$40 for 300 Emails
                                    </Button>
                                </Col>
                            </Row>
                            <Row justify={"space-around"} align={"middle"} className="w-100 mt-20">
                                <Col span={24} lg={7}>
                                    <Button onClick={nextSubStep}
                                            className="onboarding-button gradient-border">$60 for 500 Emails
                                    </Button>
                                </Col>
                                <Col span={24} lg={7}>
                                    <Button onClick={nextSubStep}
                                            className="onboarding-button gradient-border">$80 for 800 Emails
                                    </Button>
                                </Col>
                            </Row>
                        </>}
                        { subStep === 4 && <>
                            <div className="onboarding-text gradient-border">
                                Ok, now we need to buy a database for email outreach. Purchase a business database
                            </div>
                            <Row justify={"space-around"} align={"middle"} className="w-100 onboarding-actions">
                                <Col span={24} lg={7}>
                                    <Button onClick={nextSubStep}
                                            className="onboarding-button gradient-border">$20 for 80 Business Connects
                                    </Button>
                                </Col>
                                <Col span={24} lg={7}>
                                    <Button onClick={nextSubStep}
                                            className="onboarding-button gradient-border">$30 for 130 Business Connects
                                    </Button>
                                </Col>
                            </Row>
                            <Row justify={"space-around"} align={"middle"} className="w-100 mt-20">
                                <Col span={24} lg={7}>
                                    <Button onClick={nextSubStep}
                                            className="onboarding-button gradient-border">$60 for 400 Business Connects
                                    </Button>
                                </Col>
                                <Col span={24} lg={7}>
                                    <Button onClick={nextSubStep}
                                            className="onboarding-button gradient-border">$200 for 1,600 Business Connects
                                    </Button>
                                </Col>
                            </Row>
                        </>}
                        { subStep === 5 && <>
                            <div className="onboarding-text gradient-border">
                                Now let us make and email campagin
                            </div>
                            <Row justify={"space-around"} align={"middle"} className="w-100 onboarding-actions">
                                <Col span={24} lg={6}>
                                    <Button onClick={nextStep}
                                            className="onboarding-button gradient-border">Design and Email Campaign
                                    </Button>
                                </Col>
                            </Row>

                        </>}
                    </>}
                </>}
            </div>}
            {step === 23 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    Good going. It has been six months since we hit the market, and we are doing good. But all of a
                    sudden there is a decline in customer footfall!
                </div>
                <div className="onboarding-actions">
                    <Row justify={"center"} align={"middle"} className="w-100">
                    <Col span={6}>
                            <button onClick={nextStep} className="onboarding-button gradient-border">Check Business
                                Graph
                            </button>
                        </Col>
                    </Row>
                </div>
            </div>}
            <Modal
                open={showComboModal}
                className={"onboarding-modal"}
                centered
                width={1080}
                footer={(_, {Okbtn}) => (
                    <>
                    </>
                )}>
                <Form
                    layout="vertical"
                    requiredMark={false}
                    onFinish={onFinishCombo}
                    className="w-100"
                >
                    <Row className="w-100">
                        <Col span={24} lg={4}>
                            <div className={"modal-sidebar"}>
                                <div className={"text-center"}>
                                    <AuthLogo/>
                                </div>

                                <div className={"combo-names"}>
                                    {[...Array(maxCombos)].map((x, i) =>
                                        <p key={i} onClick={() => setComboModalCombo(i + 1)}
                                           className={"cursor-pointer " + (comboModalCombo === i + 1 ? "current" : "")}>Combo {i + 1}</p>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col span={24} lg={16} className={"combo-options"}>
                            {[...Array(maxCombos)].map((x, i) =>
                                <div className={comboModalCombo !== i + 1 ? "d-none" : ""} key={i}>
                                    <div className={"combo-title"}>
                                        Combo {i + 1}
                                    </div>
                                    <div className={"combo-selection"}>
                                        <h5>Include Item</h5>
                                        <Row>
                                            <Col span={12} lg={6}>
                                                <Checkbox value="burger"
                                                          name={"items[" + i + "][]"}>Burger</Checkbox>
                                            </Col>
                                            <Col span={12} lg={6}>
                                                <Checkbox value="soda" name={"items[" + i + "][]"}>Soda</Checkbox>
                                            </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12} lg={6}>
                                            <Checkbox value="fries" name={"items[" + i + "][]"}>Fries</Checkbox>
                                        </Col>
                                        <Col span={12} lg={6}>
                                            <Checkbox value="milkshake"
                                                      name={"items[" + i + "][]"}>Milkshake</Checkbox>
                                        </Col>
                                    </Row>
                                    <Row justify={"center"} className={"mt-20"}>
                                        <Col span={24} lg={12}>
                                            <Form.Item
                                                hasFeedback
                                                name="price[]"
                                                className={"m-0"}
                                                rules={[{required: true, message: 'Please enter combo price'}]}
                                            >
                                                <InputNumber min={0.1} step={"any"} rootClassName="form-control"
                                                             placeholder={"Input price for combo"}></InputNumber>
                                            </Form.Item>

                                            {i + 1 === maxCombos &&
                                                <Button type="submit" htmlType="submit">Create Combo</Button>
                                            }
                                            {i + 1 < maxCombos &&
                                                <Button onClick={() => {
                                                    setComboModalCombo(i + 2)
                                                }} type="submit" htmlType="button">Create Combo</Button>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        )}
                    </Col>
                    <Col span={24} lg={4} className={"d-flex justify-content-end"}>
                        <Button type="submit" className="arrow-button gradient-border m-0 mt-auto"
                                htmlType="submit">
                            <ArrowRightOutlined/>
                        </Button>
                    </Col>
                </Row>
            </Form>
            </Modal>
        </div>
    )
}